<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="rewardKits"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        {{ title }}
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
const title = 'UGU'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: title, disabled: true,
        },
      ],
      rewardKits: [
        {
          icon: 'mdi-account-multiple-outline',
          path: '/ugu_list',
          title: 'UGU List',
          desc: 'List UGU whom you\'ve introduced.',
        },
        {
          icon: 'mdi-account-arrow-left-outline',
          path: '/ugu_reloads',
          title: 'UGU Reloads',
          desc: 'Reloads done by UGU.',
        },
        {
          icon: 'mdi-wallet-giftcard',
          path: '/ugu_rewards',
          title: 'UGU Rewards',
          desc: 'Rewards given on UGU reloads.',
        },
      ],
      title,
    }
  },
}
</script>
